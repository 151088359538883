// Core
import { lazy, LazyExoticComponent } from 'react';
// Helpers
import { PERMISSION_KEYS } from 'helpers/data/constants';

/**
  Property definition:
  @property  path - route path
  @property  label - label of navigation (first and second level, use for Intl - translation key)
  @property  type - if type === "list" - set default query params to the url for pages with table lists (page and limit params)
  @property  exact - exact prop for route
  @property  private - use for defining if the route is private (can be shown only for auth user)
  @property  restricted - use for defining the route which is restricted for auth user (can't be displayed)
  @property  isNavigate - use for first level navigation (it defines the necessary of the display navigation item)
  @property  isAvailable - defines whether the page can be displayed without authorization
  @property  permissionKey - permission key = defines whether the page should be displayed depending on the user's permissions
  @property  routes - sub routes of main route
  @property  component - lazy component
 */

/**
 * @description 1 step: defines allowed routes. Filtering (preparing) routes depending on isAvailable and permissionKey props.
 *              If the user is not auth return only public routes.
 *              If the user is auth return public and private according to the user's permissions
 * @description 2 step: allowed routes divided into public and private routes according to the private prop.
 * @description 3 step: In the Private route we check if the user is auth. If he isn't auth - redirect to the Intro page.
 *              In the Public route we check if the user is auth and route is restricted.
 *              If the user is auth and route is restricted - redirect to the GPS page. In another case - render component.
 */

export type TRoute = {
  path: string;
  label: string;
  type?: 'list' | 'default';
  exact: boolean;
  private: boolean;
  restricted: boolean;
  isNavigate: boolean;
  isAvailable: boolean;
  permissionKey: string;
  routes: TSubRoute[];
  component: any;
  isAvailableWithoutSubscription?: boolean;
};

export type TSubRoute = {
  path: string;
  label: string;
  exact: boolean;
  type?: 'list' | 'default';
  private?: boolean;
  isAvailable: boolean;
  permissionKey: string;
  component: LazyExoticComponent<(props: any) => JSX.Element | null>;
  isAvailableWithoutSubscription?: boolean;
};

export const ROUTES = {
  //** */ AUTH ROUTES AND APP LINKS
  INTRO: '/',
  MFA: '/mfa',
  SIGN_IN: '/sign-in',
  SIGN_IN_COMPANY: '/:company',
  SIGN_IN_AS_ADMIN: '/:company/from-admin',
  SIGN_UP: '/sign-up',
  FORGOT_WEB: '/forgot-web-address',
  FORGOT_PWD: '/forgot-password',
  RECOVER_PWD: '/recover-password',
  GOOGLE: 'https://play.google.com/store/apps/details?id=com.timego',
  APPLE: 'https://apps.apple.com/ua/app/timego/id1556474765',
  WELCOME: '/welcome',
  TERMS: '/terms',
  CALLBACKS: '/callback',
  PRIVACY: '/privacy',
  FEEDBACK: '/:company/feedback',
  CONTACTS: '/contacts',
  HELP: '/help',
  //** */ ROUTES IN THE PLATFORM and MAIN NAVIGATION
  ACTIVITY: '/:company/activity',
  SCHEDULE: '/:company/schedule',
  //** L1: ALL COMPANIES INFO (COMPANIES/CONTACTS/LOCATIONS LISTS )  */
  COMPANIES: '/:company/companies',
  COMPANIES_LOCATIONS_LIST: '/:company/companies/locations',
  COMPANIES_LIST: '/:company/companies/clients',
  COMPANIES_CONTACTS_LIST: '/:company/companies/contacts',
  COMPANIES_LOCATION_DOCS: '/:company/companies/location-docs',
  COMPANIES_LOCATION_RATING: '/:company/companies/location-rating',
  //** L2: DETAILS OF CURRENT COMPANY (COMPANY_PROFILE/CONTACTS/JOBS/LOCATIONS LISTS ) */
  COMPANY_DETAILS: '/:company/companies/info/:accountName',
  COMPANY_DETAILS_PROFILE: '/:company/companies/info/:accountName/profile',
  COMPANY_DETAILS_CONTACTS_LIST: '/:company/companies/info/:accountName/contacts',
  COMPANY_DETAILS_JOBS_LIST: '/:company/companies/info/:accountName/jobs',
  COMPANY_DETAILS_LOCATIONS_LIST: '/:company/companies/info/:accountName/locations',
  //** L2: OTHER DETAILS (EXCEPT OF COMPANY_PROFILE) OF CURRENT COMPANY (CONTACT_PROFILE, JOB_PROFILE, LOCATION_INFO (INCLUDE 2 PAGES)) */
  COMPANY_DETAILS_CONTACT_PROFILE: '/:company/companies/info/:accountName/contacts/:contactId',
  COMPANY_DETAILS_LOCATION_INFO: '/:company/companies/info/:accountName/locations/:locationId',
  COMPANY_DETAILS_LOCATION_DOCUMENT_INFO:
    '/:company/companies/info/:accountName/locations/:locationId/location-docs/:documentId',
  //** L3: DETAILS OF LOCATION OF CURRENT COMPANY */
  COMPANY_DETAILS_LOCATION_INFO_LOCATION_PROFILE:
    '/:company/companies/info/:accountName/locations/:locationId/profile',
  COMPANY_DETAILS_LOCATION_INFO_JOBS_LIST:
    '/:company/companies/info/:accountName/locations/:locationId/jobs',
  COMPANY_DETAILS_LOCATION_INFO_DOCS_LIST:
    '/:company/companies/info/:accountName/locations/:locationId/location-docs',
  //** L1: ALL JOBS INFO (JOBS/JOB_GROUPS LISTS )  */
  JOBS: '/:company/jobs',
  JOBS_LIST: '/:company/jobs',
  JOBS_RATES: '/:company/jobs/rates',
  JOBS_GROUPS_LIST: '/:company/jobs/groups',
  JOB_LOG: '/:company/jobs/logs',
  JOB_EXPENSES: '/:company/jobs/expenses',
  //** L2: DETAILS OF CURRENT JOB */
  JOB_DETAILS: '/:company/jobs/info/:jobId',
  JOB_DETAILS_PROFILE: '/:company/jobs/info/:jobId/profile',
  JOB_DETAILS_RATES: '/:company/jobs/info/:jobId/rates',
  JOB_DETAILS_LOG: '/:company/jobs/info/:jobId/log',
  JOB_DETAILS_EXPENSES: '/:company/jobs/info/:jobId/expenses',
  //** L2: OTHER DETAILS (EXCEPT OF JOB_PROFILE) */
  JOB_DETAILS_LOG_PROFILE: '/:company/jobs/info/:jobId/log/:id',
  JOB_DETAILS_EXPENSES_PROFILE: '/:company/jobs/info/:jobId/expenses/:id',
  //** L1: CHECKPOINTS  */
  CHECKPOINTS: '/:company/checkpoints',
  CHECKPOINTS_LIST: `/:company/checkpoints`,
  CHECKPOINTS_SCANS_LIST: '/:company/checkpoints/scans',
  CHECKPOINTS_PARKING_LIST: '/:company/checkpoints/parking',
  CHECKPOINTS_INCIDENTS_LIST: '/:company/checkpoints/incidents',
  CHECKPOINTS_SETTINGS: '/:company/checkpoints/settings',
  //** L2: CHECKPOINTS DETAILS  */
  CHECKPOINT_DETAILS: `/:company/checkpoints/info/:id`,
  CHECKPOINTS_SCAN_DETAILS: '/:company/checkpoints/scans/:id',
  CHECKPOINTS_PARKING_ISSUE_DETAILS: '/:company/checkpoints/parking/:id',
  CHECKPOINTS_INCIDENT_DETAILS: '/:company/checkpoints/incidents/:id',
  //** EMPLOYEE  */
  EMPLOYEES: '/:company/employees',
  EMPLOYEES_LIST: '/:company/employees',
  EMPLOYEES_GROUPS: '/:company/employees/groups',
  EMPLOYEES_LOGS: '/:company/employees/performance-logs',
  EMPLOYEES_LEAVES: '/:company/employees/leave-requests',
  EMPLOYEES_RATES: '/:company/employees/rates',
  EMPLOYEES_DOCS: '/:company/employees/docs',
  EMPLOYEE_DETAILS: '/:company/employees/info/:employeeId',
  EMPLOYEE_DETAILS_PROFILE: '/:company/employees/info/:employeeId/profile',
  EMPLOYEE_DETAILS_RATES: '/:company/employees/info/:employeeId/rates',
  EMPLOYEE_DETAILS_RATE_INFO: '/:company/employees/info/:employeeId/rates/:rateId',
  EMPLOYEE_DETAILS_TODOS: '/:company/employees/info/:employeeId/todos',
  EMPLOYEE_DETAILS_TODO_INFO: '/:company/employees/info/:employeeId/todos/:todoId',
  EMPLOYEE_DETAILS_LOGS: '/:company/employees/info/:employeeId/performance-logs',
  EMPLOYEE_DETAILS_LOG_INFO: '/:company/employees/info/:employeeId/performance-logs/:logId',
  EMPLOYEE_DETAILS_LEAVES: '/:company/employees/info/:employeeId/leave-requests',
  EMPLOYEE_DETAILS_LEAVE_INFO: '/:company/employees/info/:employeeId/leave-requests/:leaveId',
  EMPLOYEE_DETAILS_DOCS: '/:company/employees/info/:employeeId/docs',
  EMPLOYEE_DETAILS_DOC_INFO: '/:company/employees/info/:employeeId/docs/:docId',
  FORMS: '/:company/forms',
  FORM_DETAILS: '/:company/forms/:formId',
  FORM_DETAILS_VIEW: `/:company/forms/:formId/view`,
  FORM_DETAILS_RESULTS: `/:company/forms/:formId/results`,
  FORM_DETAILS_RESULTS_ANALYSIS: `/:company/forms/:formId/results-analysis`,
  FORM_RESULT_DETAILS: `/:company/forms/:formId/results/:resultId/info`,
  PUBLIC_FORM: `/:company/form/:urlTag`,
  GPS: '/:company/gps',
  TODO: '/:company/todo',
  TODO_DETAILS: '/:company/todo/:todoId',
  //** L1: ALL EQUIPMENT INFO (EQUIPMENT/EQUIPMENT LOGS/EQUIPMENT EXPENSES )  */
  EQUIPMENT: '/:company/equipment',
  EQUIPMENT_LIST: '/:company/equipment',
  EQUIPMENT_LOGS: '/:company/equipment/log',
  EQUIPMENT_EXPENSES: '/:company/equipment/expenses',
  //** L2: DETAILS OF CURRENT COMPANY (COMPANY_PROFILE/CONTACTS/JOBS/LOCATIONS LISTS ) */
  EQUIPMENT_DETAILS: '/:company/equipment/info/:equipmentId',
  EQUIPMENT_DETAILS_PROFILE: '/:company/equipment/info/:equipmentId/profile',
  EQUIPMENT_DETAILS_LOGS: '/:company/equipment/info/:equipmentId/log',
  EQUIPMENT_DETAILS_EXPENSES: '/:company/equipment/info/:equipmentId/expenses',
  //** L2: OTHER DETAILS (EXCEPT OF JOB_PROFILE) */
  EQUIPMENT_DETAILS_LOGS_PROFILE: '/:company/equipment/info/:equipmentId/log/:id',
  EQUIPMENT_DETAILS_EXPENSES_PROFILE:
    '/:company/equipment/info/:jobId/:equipmentId/expenses/:id',
  //** L1: MAP  */
  MAP: '/:company/map',
  //** L1: CALENDAR  */
  CALENDAR: '/:company/calendar',
  //** L1: REPORTS  */
  REPORTS: '/:company/reports',
  REPORTS_GENERAL: '/:company/reports',
  REPORTS_USAGE: '/:company/reports/usage',
  REPORTS_OUTSIDE_GEOFENCE: '/:company/reports/geofence',
  //** L1: PAYMENT  */
  PAYMENT: '/:company/payment',
  PAYMENT_PAYMENTS_LIST: '/:company/payment',
  PAYMENT_INVOICES_LIST: '/:company/payment/invoices',
  PAYMENT_CONFIRMED: '/:company/payment/confirmed',
  PAYMENT_DECLINED: '/:company/payment/declined',
  //** L1: INVOICES  */
  INVOICES: '/:company/invoices',
  INVOICES_REGISTER: '/:company/invoices',
  INVOICES_AGING: '/:company/invoices/aging-invoices',
  INVOICES_SETTINGS: '/:company/invoices/settings',
  INVOICES_RECURRING: '/:company/invoices/recurring',
  INVOICES_UNINVOICED_HOURS: '/:company/invoices/uninvoiced-hours',
  INVOICES_RECURRING_PROFILE: '/:company/invoices/recurring/:id',
  //** PREFERENCES  */,
  PREFERENCES: '/:company/preferences',
  //** PAYROLL  */,
  PAYROLL: '/:company/payroll-periods',
  PAYROLL_PERIODS: '/:company/payroll-periods',
  PAYROLL_PERIODS_HOLIDAYS: '/:company/payroll-periods/holidays',
  PAYROLL_ADJUSTMENT: '/:company/payroll-periods/adjustment',
  //** INTEGRATIONS  */,
  INTEGRATIONS: '/:company/integrations',
  //** ACTION LOGS  */,
  ACTION_LOGS: '/:company/action-logs',
  //** IMPLEMENTATION PLAN  */,
  IMPLEMENTATION_PLANS: '/:company/implementation-plan',
  //** RATES  */
  RATING: '/:company/rating',
};

const routes: TRoute[] = [
  {
    path: ROUTES.INTRO,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: true,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    isAvailableWithoutSubscription: true,
    component: lazy(() => import('pages/IntroPage' /* webpackChunkName: "intro-page" */)),
    routes: [],
  },
  {
    path: ROUTES.MFA,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: true,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    isAvailableWithoutSubscription: true,
    component: lazy(() => import('pages/MFAPage' /* webpackChunkName: "mfa-page" */)),
    routes: [],
  },
  {
    path: ROUTES.TERMS,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: false,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    isAvailableWithoutSubscription: true,
    component: lazy(() => import('pages/Terms' /* webpackChunkName: "terms-page" */)),
    routes: [],
  },
  //** */ Callbacks PAGE
  {
    path: ROUTES.CALLBACKS,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: false,
    isNavigate: false,
    isAvailable: true,
    permissionKey: PERMISSION_KEYS.CALLBACKS,
    isAvailableWithoutSubscription: true,
    component: lazy(() => import('pages/Callbacks' /* webpackChunkName: "terms-page" */)),
    routes: [],
  },
  {
    path: ROUTES.PRIVACY,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: false,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    isAvailableWithoutSubscription: true,
    component: lazy(() => import('pages/Privacy' /* webpackChunkName: "privacy-page" */)),
    routes: [],
  },
  {
    path: ROUTES.CONTACTS,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: false,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    isAvailableWithoutSubscription: true,
    component: lazy(() => import('pages/Contacts' /* webpackChunkName: "contacts-page" */)),
    routes: [],
  },
  {
    path: ROUTES.SIGN_IN,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: true,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    component: lazy(() => import('pages/SignInPage' /* webpackChunkName: "sign-in-page" */)),
    routes: [],
  },
  {
    path: ROUTES.SIGN_UP,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: true,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    component: lazy(() => import('pages/SignUpPage' /* webpackChunkName: "sign-up-page" */)),
    routes: [],
  },
  {
    path: ROUTES.FORGOT_WEB,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: true,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    component: lazy(
      () => import('pages/ForgotAccountNamePage' /* webpackChunkName: "forgot-web-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.FORGOT_PWD,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: true,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    component: lazy(
      () => import('pages/ForgotPassPage' /* webpackChunkName: "forgot-pass-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.RECOVER_PWD,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: true,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    component: lazy(
      () => import('pages/RecoverPassPage' /* webpackChunkName: "recover-pass-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.WELCOME,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: false,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    component: lazy(() => import('pages/WelcomePage' /* webpackChunkName: "welcome-page" */)),
    routes: [],
  },
  {
    path: ROUTES.SIGN_IN_COMPANY,
    label: '',
    exact: true,
    type: 'default',
    private: false,
    restricted: true,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    component: lazy(
      () =>
        import(
          'pages/SignInCompanyPage' /* webpackChunkName: "sign-in-company-page-step-2" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.ACTIVITY,
    label: 'activityPage',
    exact: true,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.ACTIVITY,
    routes: [],
    component: lazy(
      () => import('pages/ActivitiesPage' /* webpackChunkName: "activity-page" */),
    ),
  },
  {
    path: ROUTES.SCHEDULE,
    label: 'schedulePage',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.SCHEDULE,
    routes: [],
    component: lazy(
      () => import('pages/SchedulePage' /* webpackChunkName: "schedule-page" */),
    ),
  },
  //** */ COMPANIES SECTION
  {
    path: ROUTES.COMPANY_DETAILS_CONTACT_PROFILE,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.CONTACTS,
    component: lazy(
      () =>
        import(
          'pages/Company/ContactProfilePage' /* webpackChunkName: "company-details-contact-profile-page" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.COMPANY_DETAILS_LOCATION_DOCUMENT_INFO,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.LOCATIONS_DOCS,
    component: lazy(
      () =>
        import(
          'pages/Company/LocationDocProfilePage' /* webpackChunkName: "location-documents-info-page" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.COMPANY_DETAILS_LOCATION_INFO,
    label: '',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.LOCATIONS,
    component: lazy(
      () =>
        import(
          'pages/Company/LocationInfoPage' /* webpackChunkName: "company-details-location-info-page" */
        ),
    ),
    routes: [
      {
        path: ROUTES.COMPANY_DETAILS_LOCATION_INFO_LOCATION_PROFILE,
        label: 'locationDetails',
        exact: true,
        type: 'default',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.LOCATIONS,
        component: lazy(
          () =>
            import(
              'pages/Company/LocationInfoPage/components/subpages/LocationProfilePage' /* webpackChunkName: "company-details-location-profile-page" */
            ),
        ),
      },
      {
        path: ROUTES.COMPANY_DETAILS_LOCATION_INFO_JOBS_LIST,
        label: 'locationJobs',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOBS,
        component: lazy(
          () =>
            import(
              'pages/Company/LocationInfoPage/components/subpages/LocationJobsListPage' /* webpackChunkName: "company-details-job-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.COMPANY_DETAILS_LOCATION_INFO_DOCS_LIST,
        label: 'locationsDocs',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.LOCATIONS_DOCS,
        component: lazy(
          () =>
            import(
              'pages/Company/LocationInfoPage/components/subpages/LocationsDocsListPage' /* webpackChunkName: "company-details-locations-docs-page" */
            ),
        ),
      },
    ],
  },
  {
    path: ROUTES.COMPANY_DETAILS,
    label: '',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.COMPANIES,
    component: lazy(
      () =>
        import(
          'pages/Company/CompanyDetailsPage' /* webpackChunkName: "company-details-page" */
        ),
    ),
    routes: [
      {
        path: ROUTES.COMPANY_DETAILS_PROFILE,
        label: 'companiesDetailsProfile',
        exact: true,
        type: 'default',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.COMPANIES,
        component: lazy(
          () =>
            import(
              'pages/Company/CompanyDetailsPage/components/subpages/ProfilePage' /* webpackChunkName: "company-details-profile-page" */
            ),
        ),
      },
      {
        path: ROUTES.COMPANY_DETAILS_CONTACTS_LIST,
        label: 'companiesDetailsContacts',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.CONTACTS,
        component: lazy(
          () =>
            import(
              'pages/Company/CompanyDetailsPage/components/subpages/ContactsListPage' /* webpackChunkName: "company-details-contacts-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.COMPANY_DETAILS_JOBS_LIST,
        label: 'companiesDetailsJobs',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOBS,
        component: lazy(
          () =>
            import(
              'pages/Company/CompanyDetailsPage/components/subpages/JobsListPage' /* webpackChunkName: "company-details-jobs-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.COMPANY_DETAILS_LOCATIONS_LIST,
        label: 'companiesDetailsLocation',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.LOCATIONS,
        component: lazy(
          () =>
            import(
              'pages/Company/CompanyDetailsPage/components/subpages/LocationsListPage' /* webpackChunkName: "company-details-locations-list-page" */
            ),
        ),
      },
    ],
  },
  {
    path: ROUTES.COMPANIES,
    label: 'companiesPage',
    exact: false,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.COMPANIES,
    component: lazy(
      () => import('pages/Company/CompaniesPage' /* webpackChunkName: "companies-page" */),
    ),
    routes: [
      {
        path: ROUTES.COMPANIES_LOCATIONS_LIST,
        label: 'companiesLocations',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.LOCATIONS,
        component: lazy(
          () =>
            import(
              'pages/Company/CompaniesPage/components/subPages/LocationsListPage' /* webpackChunkName: "locations-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.COMPANIES_LIST,
        label: 'companiesList',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.COMPANIES,
        component: lazy(
          () =>
            import(
              'pages/Company/CompaniesPage/components/subPages/CompaniesListPage' /* webpackChunkName: "companies-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.COMPANIES_CONTACTS_LIST,
        label: 'companiesContacts',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.CONTACTS,
        component: lazy(
          () =>
            import(
              'pages/Company/CompaniesPage/components/subPages/ContactsListPage' /* webpackChunkName: "contacts-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.COMPANIES_LOCATION_DOCS,
        label: 'companiesDocs',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.LOCATIONS_DOCS,
        component: lazy(
          () =>
            import(
              'pages/Company/CompaniesPage/components/subPages/LocationsDocsListPage'
            ) /* webpackChunkName: "locations-docs-list-page" */,
        ),
      },
      {
        path: ROUTES.COMPANIES_LOCATION_RATING,
        label: 'companiesLocRating',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.LOCATION_RATING_MAIN_COMPANY,
        component: lazy(
          () =>
            import(
              'pages/Company/CompaniesPage/components/subPages/LocationsRatingListPage'
            ) /* webpackChunkName: "locations-rating-list-page" */,
        ),
      },
    ],
  },
  //** */ JOBS SECTION
  {
    path: ROUTES.JOB_DETAILS_LOG_PROFILE,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.JOB_LOGS,
    component: lazy(
      () =>
        import('pages/Jobs/JobLogProfilePage' /* webpackChunkName: "job-logs-profile-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.JOB_DETAILS_EXPENSES_PROFILE,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.JOBS,
    component: lazy(
      () =>
        import(
          'pages/Jobs/JobExpensesProfilePage' /* webpackChunkName: "job-details-expanses-profile-page" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.JOB_DETAILS,
    label: '',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.JOBS,
    component: lazy(
      () =>
        import(
          'pages/Jobs/JobDetailsPages/JobDetailsPage' /* webpackChunkName: "jobs-details-page" */
        ),
    ),
    routes: [
      {
        path: ROUTES.JOB_DETAILS_PROFILE,
        label: 'jobsDetails',
        exact: true,
        type: 'default',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOBS,
        component: lazy(
          () =>
            import(
              'pages/Jobs/JobDetailsPages/components/subpages/JobProfilePage' /* webpackChunkName: "jobs-profile-page" */
            ),
        ),
      },
      {
        path: ROUTES.JOB_DETAILS_RATES,
        label: 'jobsDetailsRates',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOB_RATES,
        component: lazy(
          () =>
            import(
              'pages/Jobs/JobDetailsPages/components/subpages/JobRatesListPage' /* webpackChunkName: "jobs-details-rates" */
            ),
        ),
      },
      {
        path: ROUTES.JOB_DETAILS_LOG,
        label: 'jobsDetailsLog',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOB_LOGS,
        component: lazy(
          () =>
            import(
              'pages/Jobs/JobDetailsPages/components/subpages/JobLogListPage' /* webpackChunkName: "jobs-details-logs" */
            ),
        ),
      },
      {
        path: ROUTES.JOB_DETAILS_EXPENSES,
        label: 'jobsDetailsExpenses',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOBS,
        component: lazy(
          () =>
            import(
              'pages/Jobs/JobDetailsPages/components/subpages/JobExpensesListPage' /* webpackChunkName: "jobs-details-expenses" */
            ),
        ),
      },
    ],
  },
  {
    path: ROUTES.JOBS,
    label: 'jobsPage',
    exact: false,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.JOBS,
    component: lazy(
      () => import('pages/Jobs/JobsPage/JobsPage' /* webpackChunkName: "jobs-page" */),
    ),
    routes: [
      {
        path: ROUTES.JOBS_LIST,
        label: 'jobsList',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOBS,
        component: lazy(
          () =>
            import(
              'pages/Jobs/JobsPage/components/subPages/JobsListPage' /* webpackChunkName: "jobs-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.JOBS_RATES,
        label: 'jobsRates',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOB_RATES,
        component: lazy(
          () =>
            import(
              'pages/Jobs/JobsPage/components/subPages/JobRatesPage' /* webpackChunkName: "jobs-rates-page" */
            ),
        ),
      },
      {
        path: ROUTES.JOBS_GROUPS_LIST,
        label: 'jobsGroups',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOB_GROUPS,
        component: lazy(
          () =>
            import(
              'pages/Jobs/JobsPage/components/subPages/JobGroupsListPage' /* webpackChunkName: "jobs-groups-page" */
            ),
        ),
      },
      {
        path: ROUTES.JOB_LOG,
        label: 'jobLog',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOB_LOGS,
        component: lazy(
          () =>
            import(
              'pages/Jobs/JobsPage/components/subPages/JobLogListPage' /* webpackChunkName: "jobs-logs-page" */
            ),
        ),
      },
      {
        path: ROUTES.JOB_EXPENSES,
        label: 'jobExpenses',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.JOB_EXPENSES,
        component: lazy(
          () =>
            import(
              'pages/Jobs/JobsPage/components/subPages/JobExpensesListPage' /* webpackChunkName: "jobs-expenses-page" */
            ),
        ),
      },
    ],
  },
  //** */ EMPLOYEE SECTION
  {
    path: ROUTES.EMPLOYEE_DETAILS_LOG_INFO,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.PERFORMANCE_LOG,
    component: lazy(
      () =>
        import('pages/Employee/PerfLogDetailsPage' /* webpackChunkName: "log-details-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.EMPLOYEE_DETAILS_LEAVE_INFO,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.LEAVE_REQUESTS,
    component: lazy(
      () =>
        import('pages/Employee/LeaveDetailsPage' /* webpackChunkName: "leave-details-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.EMPLOYEE_DETAILS_RATE_INFO,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.EMPLOYEE_RATES,
    component: lazy(
      () =>
        import('pages/Employee/RatesDetailsPage' /* webpackChunkName: "rate-details-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.EMPLOYEE_DETAILS_DOC_INFO,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.TRAININGS_AND_LICENSES,
    component: lazy(
      () => import('pages/Employee/DocDetailsPage' /* webpackChunkName: "doc-details-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.EMPLOYEE_DETAILS,
    label: '',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.EMPLOYEES,
    component: lazy(
      () =>
        import(
          'pages/Employee/EmployeeDetailsPage' /* webpackChunkName: "employee-details-page" */
        ),
    ),
    routes: [
      {
        path: ROUTES.EMPLOYEE_DETAILS_PROFILE,
        label: 'profile',
        exact: true,
        type: 'default',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EMPLOYEES,
        component: lazy(
          () => import('pages/Employee/EmployeeDetailsPage/components/subPages/ProfilePage'),
        ),
      },
      {
        path: ROUTES.EMPLOYEE_DETAILS_RATES,
        label: 'rates',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EMPLOYEE_RATES,
        component: lazy(
          () => import('pages/Employee/EmployeeDetailsPage/components/subPages/RatesPage'),
        ),
      },
      {
        path: ROUTES.EMPLOYEE_DETAILS_TODOS,
        label: 'todo',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.TODOS,
        component: lazy(
          () => import('pages/Employee/EmployeeDetailsPage/components/subPages/TodoPage'),
        ),
      },
      {
        path: ROUTES.EMPLOYEE_DETAILS_LOGS,
        label: 'log',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.PERFORMANCE_LOG,
        component: lazy(
          () =>
            import(
              'pages/Employee/EmployeeDetailsPage/components/subPages/PerformanceLogPage'
            ),
        ),
      },
      {
        path: ROUTES.EMPLOYEE_DETAILS_LEAVES,
        label: 'leave',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.LEAVE_REQUESTS,
        component: lazy(
          () => import('pages/Employee/EmployeeDetailsPage/components/subPages/LeavePage'),
        ),
      },
      {
        path: ROUTES.EMPLOYEE_DETAILS_DOCS,
        label: 'docs',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.TRAININGS_AND_LICENSES,
        component: lazy(
          () => import('pages/Employee/EmployeeDetailsPage/components/subPages/DocsPage'),
        ),
      },
    ],
  },
  {
    path: ROUTES.EMPLOYEES,
    label: 'employeesPage',
    exact: false,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.EMPLOYEES,
    component: lazy(
      () => import('pages/Employee/EmployeesPage' /* webpackChunkName: "employees-page" */),
    ),
    routes: [
      {
        path: ROUTES.EMPLOYEES_LIST,
        label: 'employeeList',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EMPLOYEES,
        component: lazy(
          () => import('pages/Employee/EmployeesPage/components/subPages/EmployeesListPage'),
        ),
      },
      {
        path: ROUTES.EMPLOYEES_GROUPS,
        label: 'groups',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EMPLOYEE_GROUPS,
        component: lazy(
          () => import('pages/Employee/EmployeesPage/components/subPages/GroupsPage'),
        ),
      },
      {
        path: ROUTES.EMPLOYEES_LOGS,
        label: 'log',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.PERFORMANCE_LOG,
        component: lazy(
          () => import('pages/Employee/EmployeesPage/components/subPages/PerformanceLogPage'),
        ),
      },
      {
        path: ROUTES.EMPLOYEES_LEAVES,
        label: 'leave',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.LEAVE_REQUESTS,
        component: lazy(
          () => import('pages/Employee/EmployeesPage/components/subPages/LeavePage'),
        ),
      },
      {
        path: ROUTES.EMPLOYEES_RATES,
        label: 'rates',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EMPLOYEE_RATES,
        component: lazy(
          () => import('pages/Employee/EmployeesPage/components/subPages/RatesPage'),
        ),
      },
      {
        path: ROUTES.EMPLOYEES_DOCS,
        label: 'docs',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.TRAININGS_AND_LICENSES,
        component: lazy(
          () => import('pages/Employee/EmployeesPage/components/subPages/DocsPage'),
        ),
      },
    ],
  },
  {
    path: ROUTES.FORMS,
    label: 'formsPage',
    exact: true,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.FORMS,
    routes: [],
    component: lazy(
      () => import('pages/Forms/FormsPage' /* webpackChunkName: "forms-page" */),
    ),
  },
  {
    path: ROUTES.FORM_RESULT_DETAILS,
    label: '',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.FORMS,
    component: lazy(
      () => import('pages/Forms/FormResultPage' /* webpackChunkName: "form-details-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.PUBLIC_FORM,
    label: '',
    exact: false,
    type: 'default',
    private: false,
    restricted: false,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    component: lazy(
      () => import('pages/Forms/PublicFormPage' /* webpackChunkName: "public-form-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.FORM_DETAILS,
    label: '',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.FORMS,
    component: lazy(
      () => import('pages/Forms/FormDetailsPage' /* webpackChunkName: "form-details-page" */),
    ),
    routes: [
      {
        path: ROUTES.FORM_DETAILS_RESULTS,
        label: 'formResults',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.FORM_RESULTS,
        component: lazy(
          () =>
            import(
              'pages/Forms/FormDetailsPage/components/subPages/FormResultsPage' /* webpackChunkName: "form-details-page" */
            ),
        ),
      },
      {
        path: ROUTES.FORM_DETAILS_VIEW,
        label: 'formView',
        exact: true,
        type: 'default',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.FORM_VIEW,
        component: lazy(
          () =>
            import(
              'pages/Forms/FormDetailsPage/components/subPages/FormViewPage' /* webpackChunkName: "form-details-view-page" */
            ),
        ),
      },
      {
        path: ROUTES.FORM_DETAILS_RESULTS_ANALYSIS,
        label: 'formResultsAnalysis',
        exact: true,
        type: 'default',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.FORM_RESULTS,
        component: lazy(
          () =>
            import(
              'pages/Forms/FormDetailsPage/components/subPages/FormResultsAnalysisPage' /* webpackChunkName: "form-details-results -analysis-page" */
            ),
        ),
      },
    ],
  },
  {
    path: ROUTES.CHECKPOINT_DETAILS,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.CHECKPOINTS,
    component: lazy(
      () =>
        import(
          'pages/CheckpointsPage/CheckpointsDetailsPages/CheckpointDetailsPage' /* webpackChunkName: "checkpoint-details-page" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.CHECKPOINTS_INCIDENT_DETAILS,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.CHECKPOINTS,
    component: lazy(
      () =>
        import(
          'pages/CheckpointsPage/CheckpointsDetailsPages/IncidentDetailsPage' /* webpackChunkName: "incident-details-page" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.CHECKPOINTS_PARKING_ISSUE_DETAILS,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.CHECKPOINTS,
    component: lazy(
      () =>
        import(
          'pages/CheckpointsPage/CheckpointsDetailsPages/ParkingIssueDetailsPage' /* webpackChunkName: "parking-issue-details-page" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.CHECKPOINTS_SCAN_DETAILS,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.CHECKPOINTS,
    component: lazy(
      () =>
        import(
          'pages/CheckpointsPage/CheckpointsDetailsPages/ScanDetailsPage' /* webpackChunkName: "scan-details-page" */
        ),
    ),
    routes: [],
  },

  {
    path: ROUTES.CHECKPOINTS,
    label: 'checkpointsPage',
    exact: false,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.CHECKPOINTS,
    component: lazy(
      () => import('pages/CheckpointsPage' /* webpackChunkName: "checkpoints-page" */),
    ),
    routes: [
      {
        path: ROUTES.CHECKPOINTS_LIST,
        label: 'checkpointsList',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.CHECKPOINTS,
        component: lazy(
          () =>
            import(
              'pages/CheckpointsPage/components/subPages/CheckpointsListPage' /* webpackChunkName: "checkpoints-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.CHECKPOINTS_SCANS_LIST,
        label: 'checkpointScans',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.CHECKPOINTS,
        component: lazy(
          () =>
            import(
              'pages/CheckpointsPage/components/subPages/CheckpointsScansListPage' /* webpackChunkName: "checkpoints-scans-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.CHECKPOINTS_INCIDENTS_LIST,
        label: 'incidents',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.CHECKPOINTS,
        component: lazy(
          () =>
            import(
              'pages/CheckpointsPage/components/subPages/CheckpointsIncidentsPage' /* webpackChunkName: "checkpoints-incidents-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.CHECKPOINTS_PARKING_LIST,
        label: 'parking',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.CHECKPOINTS,
        component: lazy(
          () =>
            import(
              'pages/CheckpointsPage/components/subPages/CheckpointsParkingIssuesListPage' /* webpackChunkName: "checkpoints-parking-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.CHECKPOINTS_SETTINGS,
        label: 'settings',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.CHECKPOINT_SETTINGS,
        component: lazy(
          () =>
            import(
              'pages/CheckpointsPage/components/subPages/CheckpointsSettingsPage' /* webpackChunkName: "checkpoints-settings" */
            ),
        ),
      },
    ],
  },
  {
    path: ROUTES.GPS,
    label: 'gpsPage',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.GPS,
    component: lazy(() => import('pages/GpsPage' /* webpackChunkName: "GpsPage-page" */)),
    routes: [],
  },
  {
    path: ROUTES.TODO_DETAILS,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.TODOS,
    component: lazy(
      () => import('pages/Todo/TodoDetailsPage' /* webpackChunkName: "todo-details-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.TODO,
    label: 'todoPage',
    exact: true,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.TODOS,
    component: lazy(() => import('pages/Todo/TodoPage' /* webpackChunkName: "todo-page" */)),
    routes: [],
  },
  // EQUIPMENT
  {
    path: ROUTES.EQUIPMENT_DETAILS_LOGS_PROFILE,
    label: '',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.EQUIPMENT,
    component: lazy(
      () =>
        import(
          'pages/Equipment/EquipmentLogProfilePage' /* webpackChunkName: "equipment-logs-profile-page" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.EQUIPMENT_DETAILS_EXPENSES_PROFILE,
    label: '',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.EQUIPMENT,
    component: lazy(
      () =>
        import(
          'pages/Equipment/EquipmentExpensesProfilePage' /* webpackChunkName: "equipment-expenses-profile-page" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.EQUIPMENT_DETAILS,
    label: '',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.EQUIPMENT,
    component: lazy(
      () =>
        import(
          'pages/Equipment/EquipmentDetailsPage' /* webpackChunkName: "equipment-details-page" */
        ),
    ),
    routes: [
      {
        path: ROUTES.EQUIPMENT_DETAILS_PROFILE,
        label: 'equipmentDetailsProfile',
        exact: true,
        type: 'default',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EQUIPMENT,
        component: lazy(
          () =>
            import(
              'pages/Equipment/EquipmentDetailsPage/components/subPages/EquipmentDetailsProfilePage' /* webpackChunkName: "equipment-details-profile-page" */
            ),
        ),
      },
      {
        path: ROUTES.EQUIPMENT_DETAILS_LOGS,
        label: 'equipmentLog',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EQUIPMENT,
        component: lazy(
          () =>
            import(
              'pages/Equipment/EquipmentDetailsPage/components/subPages/EquipmentDetailsLogListPage' /* webpackChunkName: "equipment-details-log-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.EQUIPMENT_DETAILS_EXPENSES,
        label: 'equipmentExpenses',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EQUIPMENT,
        component: lazy(
          () =>
            import(
              'pages/Equipment/EquipmentDetailsPage/components/subPages/EquipmentDetailsExpensesListPage' /* webpackChunkName: "equipment-details-expenses-list-page" */
            ),
        ),
      },
    ],
  },
  {
    path: ROUTES.EQUIPMENT,
    label: 'equipmentPage',
    exact: false,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.EQUIPMENT,
    component: lazy(
      () =>
        import(
          'pages/Equipment/EquipmentPage/EquipmentPage' /* webpackChunkName: "equipment-page" */
        ),
    ),
    routes: [
      {
        path: ROUTES.EQUIPMENT_LIST,
        label: 'equipmentList',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EQUIPMENT,
        component: lazy(
          () =>
            import(
              'pages/Equipment/EquipmentPage/components/subPages/EquipmentListPage' /* webpackChunkName: "equipment-list-page" */
            ),
        ),
      },
      {
        path: ROUTES.EQUIPMENT_LOGS,
        label: 'equipmentLog',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EQUIPMENT,
        component: lazy(
          () =>
            import(
              'pages/Equipment/EquipmentPage/components/subPages/EquipmentLogPage' /* webpackChunkName: "equipment-logs-page" */
            ),
        ),
      },
      {
        path: ROUTES.EQUIPMENT_EXPENSES,
        label: 'equipmentExpenses',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.EQUIPMENT,
        component: lazy(
          () =>
            import(
              'pages/Equipment/EquipmentPage/components/subPages/EquipmentExpensesPage' /* webpackChunkName: "equipment-list-page" */
            ),
        ),
      },
    ],
  },

  {
    path: ROUTES.MAP,
    label: 'mapViewPage',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.MAP_VIEW,
    component: lazy(() => import('pages/MapPage' /* webpackChunkName: "map-page" */)),
    routes: [],
  },
  {
    path: ROUTES.CALENDAR,
    label: 'calendarPage',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.CALENDAR,
    component: lazy(
      () => import('pages/CalendarPage' /* webpackChunkName: "calendar-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.REPORTS,
    label: 'reportsPage',
    exact: false,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.REPORTS,
    component: lazy(() => import('pages/ReportsPage' /* webpackChunkName: "reports-page" */)),
    routes: [
      {
        path: ROUTES.REPORTS_GENERAL,
        label: 'generalReport',
        exact: true,
        type: 'default',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.REPORTS,
        component: lazy(
          () =>
            import(
              'pages/ReportsPage/components/subpages/GeneralReportPage' /* webpackChunkName: "general-report-page" */
            ),
        ),
      },
      {
        path: ROUTES.REPORTS_OUTSIDE_GEOFENCE,
        label: 'outsideGeofenceReport',
        exact: true,
        type: 'default',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.REPORTS,
        component: lazy(
          () =>
            import(
              'pages/ReportsPage/components/subpages/OutsideGeofenceReportPage' /* webpackChunkName: "outside-geofence-report-page" */
            ),
        ),
      },
      // {
      //   path: ROUTES.REPORTS_USAGE,
      //   label: 'usageReport',
      //   exact: true,
      //   type: 'default',
      //   isAvailable: false,
      //   permissionKey: PERMISSION_KEYS.REPORTS,
      //   component: lazy(
      //     () =>
      //       import(
      //         'pages/ReportsPage/components/subpages/UsageReportPage' /* webpackChunkName: "usage-report-page" */
      //       ),
      //   ),
      // },
    ],
  },
  {
    path: ROUTES.PAYMENT_CONFIRMED,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.PAYMENTS,
    isAvailableWithoutSubscription: true,
    component: lazy(
      () =>
        import(
          'pages/PaymentPage/components/subpages/PaymentConfirmedPage' /* webpackChunkName: "payment-confirmed-page" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.PAYMENT_DECLINED,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.PAYMENTS,
    isAvailableWithoutSubscription: true,
    component: lazy(
      () =>
        import(
          'pages/PaymentPage/components/subpages/PaymentDeclinedPage' /* webpackChunkName: "payment-declined-page" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.PAYMENT,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.PAYMENTS,
    isAvailableWithoutSubscription: true,
    component: lazy(() => import('pages/PaymentPage' /* webpackChunkName: "payments-page" */)),
    routes: [],
  },
  {
    path: ROUTES.INVOICES_RECURRING_PROFILE,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.INVOICES,
    component: lazy(
      () =>
        import(
          'pages/InvoicesPage/RecurringInvoiceProfilePage'
          /* webpackChunkName: "recurring-invoiceProfilePage" */
        ),
    ),
    routes: [],
  },
  {
    path: ROUTES.INVOICES,
    label: 'invoicesPage',
    exact: false,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.INVOICES,
    component: lazy(
      () => import('pages/InvoicesPage/InvoicesPage' /* webpackChunkName: "invoices-page" */),
    ),
    routes: [
      {
        path: ROUTES.INVOICES_REGISTER,
        label: 'invoiceRegister',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.INVOICES,
        component: lazy(
          () =>
            import(
              'pages/InvoicesPage/components/subpages/InvoiceRegisterListPage' /* webpackChunkName: "invoices-register" */
            ),
        ),
      },
      {
        path: ROUTES.INVOICES_AGING,
        label: 'invoiceAging',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.INVOICES,
        component: lazy(
          () =>
            import(
              'pages/InvoicesPage/components/subpages/InvoiceAgingListPage' /* webpackChunkName: "invoices-aging" */
            ),
        ),
      },
      {
        path: ROUTES.INVOICES_RECURRING,
        label: 'invoicesRecurring',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.INVOICES,
        component: lazy(
          () =>
            import(
              'pages/InvoicesPage/components/subpages/RecurringInvoicesListPage' /* webpackChunkName: "invoices-recurring" */
            ),
        ),
      },

      {
        path: ROUTES.INVOICES_UNINVOICED_HOURS,
        label: 'uninvoicedHours',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.INVOICES,
        component: lazy(
          () =>
            import(
              'pages/InvoicesPage/components/subpages/UninvoicedHoursListPage' /* webpackChunkName: "invoices-uninvoices-hours-page" */
            ),
        ),
      },
      {
        path: ROUTES.INVOICES_SETTINGS,
        label: 'invoiceSettings',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.INVOICES,
        component: lazy(
          () =>
            import(
              'pages/InvoicesPage/components/subpages/InvoiceSettingsPage' /* webpackChunkName: "invoices-settings-page" */
            ),
        ),
      },
    ],
  },
  {
    path: ROUTES.HELP,
    label: 'helpPage',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.HELP,
    isAvailableWithoutSubscription: true,
    component: lazy(() => import('pages/HelpPage' /* webpackChunkName: "help-page" */)),
    routes: [],
  },
  {
    path: ROUTES.PREFERENCES,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: true,
    permissionKey: '',
    isAvailableWithoutSubscription: true,
    component: lazy(
      () => import('pages/PreferencesPage' /* webpackChunkName: "preferences-page" */),
    ),
    routes: [],
  },
  {
    path: ROUTES.PAYROLL,
    label: '',
    exact: false,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.PAYROLL,
    component: lazy(() => import('pages/PayrollPage' /* webpackChunkName: "payroll-page" */)),
    routes: [
      {
        path: ROUTES.PAYROLL_PERIODS,
        label: 'payrollPeriods',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.PAYROLL,
        component: lazy(() => import('pages/PayrollPage/components/pages/PayrollListPage')),
      },
      {
        path: ROUTES.PAYROLL_PERIODS_HOLIDAYS,
        label: 'holidays',
        exact: true,
        type: 'list',
        isAvailable: false,
        permissionKey: PERMISSION_KEYS.PAYROLL,
        component: lazy(() => import('pages/PayrollPage/components/pages/HolidaysListPage')),
      },
      // {
      //   path: ROUTES.PAYROLL_ADJUSTMENT,
      //   label: 'payrollAdjustment',
      //   exact: true,
      //   type: 'list',
      //   isAvailable: false,
      //   permissionKey: PERMISSION_KEYS.PAYROLL_ADJUSTMENT,
      //   component: lazy(
      //     () => import('pages/PayrollPage/components/pages/PayrollAdjustmentPage'),
      //   ),
      // },
    ],
  },
  //** */ FEEDBACK PAGE
  {
    path: ROUTES.FEEDBACK,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.FEEDBACK,
    isAvailableWithoutSubscription: true,
    component: lazy(
      () => import('pages/FeedbackPage' /* webpackChunkName: "feedback-page" */),
    ),
    routes: [],
  },
  // ** */ INTEGRATIONS PAGE
  {
    path: ROUTES.INTEGRATIONS,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    isAvailableWithoutSubscription: true,
    permissionKey: PERMISSION_KEYS.INTEGRATIONS,
    routes: [],
    component: lazy(
      () => import('pages/IntegrationsPage' /* webpackChunkName: "integrationsPage-page" */),
    ),
  },
  // ** */ ACTION LOGS PAGE
  {
    path: ROUTES.ACTION_LOGS,
    label: '',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.ACTION_LOGS,
    routes: [],
    component: lazy(
      () => import('pages/ActionLogsPage' /* webpackChunkName: "actionLogs-page" */),
    ),
  },
  {
    path: ROUTES.IMPLEMENTATION_PLANS,
    label: '',
    exact: true,
    type: 'list',
    private: true,
    restricted: false,
    isNavigate: false,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.IMPLEMENTATION_PLANS,
    routes: [],
    component: lazy(
      () =>
        import('pages/ImplementationPlansPage' /* webpackChunkName: "implementation-plans" */),
    ),
  },
  // ** */ RATING PAGE
  {
    path: ROUTES.RATING,
    label: 'rating',
    exact: true,
    type: 'default',
    private: true,
    restricted: false,
    isNavigate: true,
    isAvailable: false,
    permissionKey: PERMISSION_KEYS.LOCATION_RATING,
    routes: [],
    component: lazy(() => import('pages/RatingPage' /* webpackChunkName: "Rating-page" */)),
  },
];

export default routes;
